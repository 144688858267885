import React from 'react';
import styled from 'styled-components';

export const LeftLogoFooter = ({ link1Url }) => {
    return (
        <footer
            style={{
                background: process.env.GATSBY_FOOTER_BG_COLOR,
                height: '60px',
            }}
        >
            <FooterWrapperDiv>
                <LogoDiv>
                    <LogoLink href={link1Url} />
                </LogoDiv>
            </FooterWrapperDiv>
        </footer>
    );
};

const FooterWrapperDiv = styled.div`
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    display: flex;
    margin: 0 auto;
`;

const LogoDiv = styled.div`
    width: 200px;
    height: 45px;
    margin-left: 8%;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    object-position: left;
    display: flex;
    position: relative;
    background-image: url('https://${process.env.GATSBY_BLOG_HAS_ITS_OWN_ASSETS === "true" ?  process.env.GATSBY_DOMAIN_NAME_NESTED : `${process.env.GATSBY_DOMAIN_NAME}/`}icons/left-footer-logo.png');
`;

const LogoLink = styled.a`
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
`;
