import React from 'react';
import { JoinFooter } from './footers/JoinFooter';
import { LeftLogoFooter } from './footers/LeftLogoFooter';
import { MiddleLogoFooter } from './footers/MiddleLogoFooter';

const FooterType = {
    joinFooter: 'joinFooter',
    leftLogo: 'leftLogo',
    middleLogo: 'middleLogo',
};

const renderFooter = (type) => {
    switch (type) {
        case FooterType.joinFooter:
            return JoinFooter;
        case FooterType.leftLogo:
            return LeftLogoFooter;
        case FooterType.middleLogo:
            return MiddleLogoFooter;
        default:
            return JoinFooter;
    }
};

const Footer = (props) => {
    const ChosenFooter = renderFooter(process.env.GATSBY_FOOTER_TYPE);
    return <ChosenFooter {...props} />;
};

export default Footer;
