import React from 'react';
import styled from 'styled-components';

export const LeftLogoHeader = ({ link1Url }) => {
    return (
        <HeaderComponent>
            <HeaderWrapperDiv>
                <LogoDiv>
                    <LogoLink href={link1Url} />
                </LogoDiv>
            </HeaderWrapperDiv>
        </HeaderComponent>
    );
};

const HeaderComponent = styled.header`
    background: ${process.env.GATSBY_HEADER_BG_COLOR};
    height: 85px;
`;

const HeaderWrapperDiv = styled.div`
    height: 100%;
    flex-direction: row;
    align-items: center;
    position: relative;
    display: flex;
    margin: 0 auto;
    margin-left: 8%;
`;

const LogoDiv = styled.div`
    width: 200px;
    height: 45px;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    object-position: left;
    display: flex;
    position: relative;
    background-image: url('https://${process.env.GATSBY_BLOG_HAS_ITS_OWN_ASSETS === "true" ?  process.env.GATSBY_DOMAIN_NAME_NESTED : `${process.env.GATSBY_DOMAIN_NAME}/`}icons/left-header-logo.png');
`;

const LogoLink = styled.a`
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
`;
