import React from 'react';
import styled from 'styled-components';

export const MiddleLogoHeader = ({ link1Text, link1Url, link2Text, link2Url }) => (
    <HeaderComponent>
        <HeaderWrapperDiv>
            {link1Text && link1Url ? (
                <LinkSpan align="right">
                    <TextLink href={link1Url}>{link1Text}</TextLink>
                </LinkSpan>
            ) : (
                <EmptySpan align="right" />
            )}
            <LogoDiv />
            {link2Text && link2Url ? (
                <LinkSpan align="left">
                    <TextLink href={link2Url}>{link2Text}</TextLink>
                </LinkSpan>
            ) : (
                <EmptySpan align="left" />
            )}
        </HeaderWrapperDiv>
    </HeaderComponent>
);

const HeaderComponent = styled.header`
    background: ${process.env.GATSBY_HEADER_BG_COLOR};
    height: 80px;
`;

const HeaderWrapperDiv = styled.div`
    height: 100%;
    max-width: 850px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    margin: 0 auto;
`;

const TextLink = styled.a`
    font-family: Poppins;
    font-weight: normal;
    text-decoration: none;
    font-size: 1.5em;
    color: ${process.env.GATSBY_HEADER_COLOR};
    border-bottom: 1px solid rgb(256, 256, 256, 0.4);
    display: flex;
    cursor: pointer;
`;

const LinkSpan = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.align === 'right' ? 'flex-end' : 'flex-start')};
    position: relative;
    padding-top: 30px;
    width: calc(calc(100% - 102px - 8em) / 2);
    ${(props) => (props.align === 'right' ? 'margin-right' : 'margin-left')} : 4em;
`;

const LogoDiv = styled.div`
    width: 102px;
    height: 102px;
    border-radius: 50%;
    border: solid 3px rgb(246, 246, 246, 0.95);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3), 0px 8px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    position: relative;
    margin-bottom: -35px;
    background-image: url('https://${
        process.env.GATSBY_BLOG_HAS_ITS_OWN_ASSETS === 'true'
            ? process.env.GATSBY_DOMAIN_NAME_NESTED
            : `${process.env.GATSBY_DOMAIN_NAME}/`
    }icons/logo-96x96.png');
`;

const EmptySpan = styled.span`
    display: flex;
    position: relative;
    width: calc(calc(100% - 102px - 8em) / 2);
    ${(props) => (props.align === 'right' ? 'margin-right' : 'margin-left')} : 4em;
`;
